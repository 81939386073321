<template>
  <div class="k-container">
    <Divider orientation="left"><h2>产品中心</h2></Divider>
    <prod-box></prod-box>
  </div>
</template>

<script>
import ProdBox from './prod-box.vue';

export default {
  name: 'index',
  data() {
    return {};
  },
  components: {
    ProdBox,
  },
};
</script>

<style scoped lang="less"></style>
